import { API, Auth } from 'aws-amplify'
import { AxiosError } from 'axios'
import { IconType } from 'components/Icons'
import { AnalyzedMagicField, MagicLetterDocumentMetaData } from 'components/MagicLetter/magic-letter-metadata.model'
import { ITimelineNoteContent, PensionerNotesQueryResult } from 'components/Timeline/Note/Note'
import { AuszahlungsOptionConfirmed } from 'components/model/auszahlungsoption/auszahlungs-option-confirmed.model'
import { trackPromise } from 'react-promise-tracker'
import { QueryFunction } from 'react-query'
import { MonatlicheBeitraege } from 'shared/components/CompensationDataTable'
import { ConfirmedCompensationType } from 'shared/enums'
import { components, paths } from 'types/api-interface'
import { IPensionerBaseData, VersorgungsausgleichStatusInfoAdminApi } from './useUserRecord'
import { getToastMessage } from 'shared/helper/getToastMessage'
import zfToast, { ZFToastProps } from 'shared/components/ZFToast'
import { t } from 'i18next'
import ja from 'shared/components/phone-number-input/locale/ja.json'

export enum RecognitionType {
	magic = 'magic',
	manual = 'manual',
}

export interface InboxItem {
	entryDate: string
	type: {
		icon: IconType
		label: string
	}
	customer: string
	recognition: RecognitionType
	asignee: string
}

export interface Employee {
	name: string
	division: string
	isOnline: boolean
}

export interface InboxData {
	items: InboxItem[]
}

export enum CommunicationType {
	APP = 'APP',
	PAPER = 'PAPER',
	UNDEFINED = 'UNDEFINED',
}

export enum TicketStatusType {
	OPEN = 'OPEN',
	CLOSED = 'CLOSED',
	IN_PROGRESS = 'IN_PROGRESS',
}

export enum TicketType {
	UNDEFINED = 'UNDEFINED',
	CANCELLATION = 'CANCELLATION',
	SIGNUP = 'SIGNUP',
	MONTHLY_DEFERRED_COMPENSATION = 'MONTHLY_DEFERRED_COMPENSATION',
	BONUS_DEFERRED_COMPENSATION = 'BONUS_DEFERRED_COMPENSATION',
	PAPER_RISK_OPTION = 'PAPER_RISK_OPTION',
	TRANSFER_CONFIRMATION = 'TRANSFER_CONFIRMATION',
	PAPER_MONTHLY_SCHEME = 'PAPER_MONTHLY_SCHEME',
	PAPER_BONUS = 'PAPER_BONUS',
}

// tslint:disable-next-line: no-empty-interface
export interface TicketDetailsObject {}
export interface TicketBonusDetails extends TicketDetailsObject {
	month?: number | string
	bonusType?: DocumentType | string
	percentage?: number | string
	amount?: number | string
}

export interface TicketSignUpDetails extends TicketDetailsObject {
	isParticipating?: boolean
	isUsingApp?: boolean
	communicationType?: CommunicationType
}
export interface TicketMonthlyDetails extends TicketDetailsObject {
	amount?: number
	percentage?: number
	date?: string
}
export interface TicketEmptyDetails extends TicketDetailsObject {
	data?: {}
}

export enum DocumentType {
	Salary = 'SALARY',
	ChristmasBonus = 'CHRISTMAS_BONUS',
	HolidayBonus = 'HOLIDAY_BONUS',
	EarningsBonus = 'EARNINGS_BONUS',
	VariableBonus = 'VARIABLE_BONUS',
	TariffAdditionalBonus = 'TARIFF_ADDITIONAL_BONUS',
	Bonus = 'BONUS',
	GratuityBonus = 'GRATUITY_BONUS',
	BonusDefferedCompensation = 'BONUS_DEFERRED_COMPENSATION',
	Undefined = 'UNDEFINED',
}

export type TicketId = string
type ISODateString = string
type TicketRemark = 'signatureMissing'
type TicketChannel = IconType

export interface NextTicketResponse {
	ticketId?: TicketId
}

export interface NextTicketRequestQueryParams {
	ticketId: TicketId
	ticketStatus: Ticket['statusInfo']['status']
	dateFilter?: string
	documentTypeFilter?: string
	problemStateFilter?: string
}

export interface PdfMergeQueryParams {
	entityId: string
	documentType: string
	fileExtension?: 'pdf' | 'zip'
}

export interface PdfMergeQueryResponse {
	isSuccessful: string
	signedUrl: string
}

export interface PasswortResetAuth2QueryParams {
	userId: string
}

export interface TicketResponse {
	tickets: Ticket[]
	firstPage: boolean
	lastPage: boolean
	firstToken?: string
	lastToken?: string
}

export interface TicketRequestQueryParams {
	maxItems?: number
	firstToken?: string
	lastToken?: string
	dateFilter?: string
	documentTypeFilter?: string
	problemStateFilter?: string
}

export type ResendDocumentResponse =
	paths['/pensioners/{identNumber}/documents/erneut-versenden']['post']['responses']['200']['content']['application/json']
export type ResendDocumentPostBody =
	paths['/pensioners/{identNumber}/documents/erneut-versenden']['post']['requestBody']['content']['application/json']
export type DocumentGetResponse =
	paths['/pensioners/{identNumber}/documents']['get']['responses']['200']['content']['application/json']
export type DocumentData = DocumentGetResponse[0]
export type AustrittsbearbeitungenResponse =
	paths['/austrittsbearbeitungen']['get']['responses']['200']['content']['application/json']

export type AustrittsbearbeitungenRequestQueryParams = paths['/austrittsbearbeitungen']['get']['parameters']['query']

export type AustrittsbearbeitungenStammdatenResponse =
	paths['/austrittsbearbeitungen/{identNumber}/stammdaten']['get']['responses']['200']['content']['application/json']

export type AustrittsbearbeitungenBearbeitendeResponse =
	paths['/austrittsbearbeitungen/bearbeitende']['get']['responses']['200']['content']['application/json']

export type AustrittsschreibenGetResponse =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-schreiben']['get']['responses']['200']['content']['application/json']
export type AustrittsbearbeitungenHistorie =
	paths['/austrittsbearbeitungen/{identNumber}/historie']['get']['responses']['200']['content']['application/json']
export type AustritttaskRequestBody =
	paths['/austrittsbearbeitungen/{identNumber}']['patch']['requestBody']['content']['application/json']
export type postAustrittsbearbeitungenAbschlussOhneVersorgungsguthabenResponse =
	paths['/austrittsbearbeitungen/{identNumber}']['post']['responses']['200']['content']['application/json']
export type AustrittsbearbeitungenAbschlussOhneVersorgungsguthabenSuccessResponse =
	paths['/austrittsbearbeitungen/{identNumber}/abschluss-ohne-versorgungsguthaben']['post']['responses']['200']
export type AustrittsbearbeitungenAbschlussOhneVersorgungsguthabenErrorResponse =
	paths['/austrittsbearbeitungen/{identNumber}/abschluss-ohne-versorgungsguthaben']['post']['responses']['400']['content']['application/json']
export type AustrittsbearbeitungenPostSuccessResponse =
	paths['/austrittsbearbeitungen/{identNumber}']['post']['responses']['200']['content']['application/json']
export type AustrittsbearbeitungenPostErrorResponse = {
	success: false
	errorMessage: string
	statusCode: number
}
export type ErrorResponse = {
	statusCode: number
}

export type EmployeeMasterData =
	paths['/pensioners/{identNumber}/profile/pensionPlan']['get']['responses']['200']['content']['application/json']
export type AustrittstaskResponse =
	paths['/austrittsbearbeitungen/{identNumber}']['get']['responses']['200']['content']['application/json']
export type AustrittstaskDeleteResponse =
	paths['/austrittsbearbeitungen/{identNumber}']['delete']['responses']['200']['content']['application/json']

export type AustrittVersorgungsguthabenResponse =
	paths['/austrittsbearbeitungen/{identNumber}/versorgungsguthaben']['get']['responses']['200']['content']['application/json']

export type AustrittsAuszahlungsOptionenResponse =
	paths['/austrittsbearbeitungen/{identNumber}/auszahlungsoptionen']['get']['responses']['200']['content']['application/json']
export type AustrittsAuszahlungsSimulationResponse =
	paths['/austrittsbearbeitungen/{identNumber}/auszahlungsoptionen']['post']['responses']['200']['content']['application/json']

export type MasterDataFlags =
	paths['/pensioners/{identNumber}/master-data-flag']['get']['responses']['200']['content']['application/json']
export type PUTMasterDataFlag =
	paths['/pensioners/{identNumber}/master-data-flag']['put']['requestBody']['content']['application/json']

export type IbanValidierungPOSTResponse =
	paths['/validate-iban']['post']['responses']['200']['content']['application/json']
export type IbanValidierungPOSTRequestBody =
	paths['/validate-iban']['post']['requestBody']['content']['application/json']
export type BankverbindungGETResponse =
	paths['/pensioners/{identNumber}/admin-gepflegte-bankverbindung']['get']['responses']['200']['content']['application/json']
export type BankverbindungRequestBody =
	| paths['/pensioners/{identNumber}/admin-gepflegte-bankverbindung']['post']['requestBody']['content']['application/json']
	| undefined
export type BankverbindungPOSTResponse =
	paths['/pensioners/{identNumber}/admin-gepflegte-bankverbindung']['post']['responses']['200']['content']['application/json']
export type BankverbindungDELETEResponse =
	paths['/pensioners/{identNumber}/admin-gepflegte-bankverbindung']['delete']['responses']['200']['content']['application/json']
export type AuszahlungsdatumGETResponse =
	paths['/pensioners/{identNumber}/auszahlungsdatum']['get']['responses']['200']['content']['application/json']
export type AuszahlungsdatumRequestBody =
	| paths['/pensioners/{identNumber}/auszahlungsdatum']['post']['requestBody']
	| undefined
export type AuszahlungsdatumPOSTResponse =
	paths['/pensioners/{identNumber}/auszahlungsdatum']['post']['responses']['200']['content']['application/json']
export type AuszahlungsdatumDELETEResponse =
	paths['/pensioners/{identNumber}/auszahlungsdatum']['delete']['responses']['200']['content']['application/json']

export type LeistungsfallEmBuGETResponse =
	paths['/pensioners/{identNumber}/leistungsfall-em-bu']['get']['responses']['200']['content']['application/json']
export type LeistungsfallEmBuRequestBody =
	| paths['/pensioners/{identNumber}/leistungsfall-em-bu']['post']['requestBody']['content']['application/json']
export type LeistungsfallEmBuPOSTResponse =
	paths['/pensioners/{identNumber}/leistungsfall-em-bu']['post']['responses']['200']['content']['application/json']

export type AustrittsbearbeitungsSimulationRequest =
	paths['/austrittsbearbeitungen/{identNumber}/auszahlungsoptionen']['post']['requestBody']['content']['application/json']
export type AustrittsbearbeitungsSimulationResponse =
	paths['/austrittsbearbeitungen/{identNumber}/auszahlungsoptionen']['post']['responses'][200]['content']['application/json']

export type UVSchreibenRequest =
	paths['/austrittsbearbeitungen/{identNumber}/uv-schreiben']['post']['responses']['200']['content']['application/json']

export type GetUVSchreibenOptionenReponse =
	paths['/austrittsbearbeitungen/{identNumber}/uv-schreiben-optionen']['get']['responses']['200']['content']['application/json']

export type PostUVSchreibenOptionenResponse =
	paths['/austrittsbearbeitungen/{identNumber}/uv-schreiben-optionen']['post']['responses']['200']['content']['application/json']

export type PostUVschreibenOptionenRequestBody =
	paths['/austrittsbearbeitungen/{identNumber}/uv-schreiben-optionen']['post']['requestBody']['content']['application/json']

export type ZusageschreibenRequest =
	paths['/austrittsbearbeitungen/{identNumber}/zusageschreiben']['post']['responses']['200']['content']['application/json']

export type BausteinkontoFinalisierungResponse =
	paths['/austrittsbearbeitungen/{identNumber}/baustein-finalisierung']['post']['responses']['200']['content']['application/json']

export type POSTAustrittDokumente =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokumente/{fallart}']['post']['requestBody']['content']['application/json']

export type GETAustrittDokumente =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokumente/{fallart}']['get']['responses']['200']['content']['application/json']

export type POSTAustrittDokumentAnfrage =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokument-anfrage']['post']['requestBody']['content']['application/json']

export type GETAustrittDokumenteAnfrageSchreiben =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokument-anfrage-schreiben']['get']['responses']['200']['content']['application/json']

export type POSTAustrittDokumenteAnfrageSchreiben =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokument-anfrage-schreiben']['post']['responses']['200']['content']['application/json']

export type GETAustrittDokumenteAnfrageSchreibenDownloadURL =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokument-anfrage-schreiben-download-url']['get']['responses']['200']['content']['application/json']

export type POSTAustrittDokumenteAnfrageSchreibenVersand =
	paths['/austrittsbearbeitungen/{identNumber}/austritt-dokument-anfrage-schreiben-versand']['post']['responses']['200']['content']['application/json']

export type getZusageschreibenOptionen =
	paths['/austrittsbearbeitungen/{identNumber}/zusageschreiben-optionen']['get']['responses']['200']['content']['application/json']

export type PostBodyZusageschreibenOptionen =
	paths['/austrittsbearbeitungen/{identNumber}/zusageschreiben-optionen']['post']['responses']['200']['content']['application/json']

export type PutAnspruchVertraglichUnverfallbarParams =
	paths['/pensioners/{identNumber}/anspruch-vertraglich-unverfallbar']['put']['requestBody']['content']['application/json']

export type PUTAbfindungNichtAnwendbarRequestBody =
	paths['/pensioners/{identNumber}/abfindung-nicht-anwendbar']['put']['requestBody']['content']['application/json']

export type PUTAdressdatenRequestBody =
	paths['/pensioners/{identNumber}/adressdaten']['put']['requestBody']['content']['application/json']

export type GETSyncConflictsResponse =
	paths['/sync-conflicts']['get']['responses']['200']['content']['application/json']

export type GETSingleSyncConflictResponse =
	paths['/sync-conflicts/{identNumber}']['get']['responses']['200']['content']['application/json']

export type POSTSingleSyncConflictResponse =
	paths['/sync-conflicts/{identNumber}']['post']['responses']['200']['content']['application/json']

export type POSTSingleSyncConflictRequestBody =
	paths['/sync-conflicts/{identNumber}']['post']['requestBody']['content']['application/json']

export type POSTConfirmedAuszahlung =
	paths['/pensioners/{identNumber}/confirmedAuszahlung']['post']['requestBody']['content']['application/json']

export type GETConfirmedAuszahlung =
	paths['/pensioners/{identNumber}/confirmedAuszahlung']['get']['responses']['200']['content']['application/json']

export type POSTSingleSyncConflictInfo = paths['/sync-conflicts/{identNumber}']['post']['parameters']['path'] &
	paths['/sync-conflicts/{identNumber}']['post']['parameters']['query']
export interface Ticket extends VersionedTicketContent {
	id: TicketId
	statusInfo: {
		status: 'open' | 'inReview' | 'closed'
		// depending of the status there may be properties like "editedBy" but you may neglect them
		editedBy?: Editor
		approvedBy?: Editor
	}
	lockedBy?: Editor
	systemReceivedAt: ISODateString
	receivedVia: TicketChannel // TicketChannel = 'email' for now
	closedWithoutAction?: boolean
}
export interface VersionedTicketContent {
	version: number // this works like an ETag, just take it and PATCH it back again
	documentContent: {
		content: MagicLetterDocumentMetaData<AnalyzedMagicField<string>> // TODO magic letter
	}
	remarks: TicketRemark[] // TicketRemark = 'signatureMissing' TODO we need to ask PO what should be possible
	predefinedRemarks: string[]
	customRemarks: string[]
	problemState?: string | null
	receivedAt: ISODateString
}
export interface Editor {
	type: 'cognito' // just ignore this
	userId: string
	salutation: Salutation
}
interface Salutation {
	firstName: string
	lastName: string
}

export interface TicketResult {
	ticket: Ticket
	documentUrl: string
}

export interface ITicketType {
	type: TicketType
	version: string
}

export interface PensionerSearchQueryParams {
	values: string[]
}

export interface PensionerSearchResult {
	firstName: string
	lastName: string
	hrSystemCode: string
	dateOfBirth: Date
	personnelNumber: string
	identNumber: string
	bavPersonalNummer: string
	participationState: string
}

export interface IPensionerProfilePreview {
	found: boolean
	identNumber: string
	lastName?: string
	firstName?: string
	endOfMandate?: string
}
export interface DocumentObject {
	url: string
	createdAt: number
}

export interface TicketObject<T> {
	ticketId?: string
	ruleSet?: string
	title?: string
	detectionType?: any
	assignee?: string | null
	details?: T
	programmeParticipantId?: string | null
	companyEmployeeId?: string | null
	createdAt?: number
	type?: TicketType
	attachedDocument?: DocumentObject
	status?: TicketStatusType
	lastModifier?: string
}

export interface SimpleChartData {
	x: number
	y: number
}

export interface PensionAssetsStringParameters {
	userId?: string
	daysPerView?: string
	year?: string
}

export interface PensionAuthData {
	pwInitial: string
	pwTries: number
	mfaInitial: string
	mfaTries: number
}

export interface PdfJob {
	entityId: string
	documentType: string
	client: string
	merged: string
	compiled: number
	numberOfPages: number
	pdfReady: number
	transferredToEAkte: number
	transferredToSpo: number
	total: number
}

export type PensionAssets =
	paths['/pensioners/{identNumber}/pensionAssets']['get']['responses']['200']['content']['application/json']

export interface IVersorgungsausgleich {
	versorgungsausgleich: ReadonlyArray<VersorgungsausgleichStatusInfoAdminApi>
}

export interface ITransaction {
	compensationType: string | undefined
	signedAt: string
	isPaperBased: boolean | undefined
	unit: string | undefined
	value: number | undefined
	start: string
	isRelevant?: boolean
}

export interface ILeistungsdaten {
	renteneintritt: string
	leistungsbeginn: string
	lohnartCode: string
	lohnartLangtext: string
	betrag: string
	meldedatum?: string
}

export interface IRiskOptions {
	currentDisabilityOption: IDisablilityOption
	disabilityTransactions: IDisablilityOption[]
	currentPartnerBenefitOption: IPartnerBenefitOption
	partnerBenefitTransactions: IPartnerBenefitOption[]
	partnerDetailTransactions: IPartnerDetailTransaction[]
}

export interface IDisablilityOption {
	confirmedAt: string | undefined
	choice: string | undefined
	isPaperBased: boolean | undefined
	start: string | undefined
	end: string | undefined
	storno: boolean
}

export interface IPartnerBenefitOption {
	confirmedAt: string | undefined
	choice: string | undefined
	isPaperBased: boolean | undefined
	start: string | undefined
	end: string | undefined
	storno: boolean
}

export interface IPartnerDetailTransaction {
	firstName: string
	lastName: string
	street: string
	zip: string
	city: string
	country: string
	dateOfBirth: string
	placeOfBirth: string
	confirmedAt: string
}

export interface IDocumentCluster {
	id: string
	types: string[]
}

export enum CompensationUnit {
	percent = 'percent',
	euro = 'euro',
}

export enum CompensationStatus {
	open = 'OFFEN',
	confirmed = 'BESTAETIGT',
}

export enum CompensationDisclaimer {
	expected = 'COMPENSATION_EXPECTED',
	different = 'COMPENSATION_DIFFERENT',
	none = 'COMPENSATION_NONE',
}

export enum CompensationType {
	salary = 'salary',
	bonus = 'bonus',
	earningsBonus = 'earningsBonus',
	christmasBonus = 'christmasBonus',
	holidayBonus = 'holidayBonus',
	variableBonus = 'variableBonus',
	tariffAdditionalBonus = 'tariffAdditionalBonus',
	gratuityBonus = 'gratuityBonus',
	avwl = 'avwl',
	boardYearlyBonus = 'boardYearlyBonus',
}

export interface TimeRangeData {
	value: string | number
	label: string
}

export interface BonusData {
	date?: number
	amount?: number
	bonusId: string
	type: CompensationType
	value?: number
	unit?: CompensationUnit
	confirmed?: boolean
	confirmedValue?: number
	confirmedUnit?: CompensationUnit
}

export interface CompensationTypeData {
	date?: number
	salaryChangesTillDate?: number
	amount?: number
	id: string
	type: CompensationType
	value?: number
	unit?: CompensationUnit
	relatedYear?: number
	relatedMonth?: number
	hasAlreadyDeferredCompensation?: boolean
}

export interface CompensationChartData {
	x: string | number
	y?: number
	y0?: number
	y1?: number
	y2?: number
}

export interface SingleCompensationData {
	salary: number
	yearlyDefferedCompensationSum: number
	percent?: number
	amount?: number
	status: CompensationStatus
	schemesForMonth: CompensationData
	bonus: BonusData[]
}

export interface CompensationData {
	id: string
	x: number
	status: CompensationStatus
	ownContribution: number
	employerBaseAmount: number
	matchingAmount: number
	bonusType: CompensationType
	employerContribution: number
	pensionTrust: number
	lastUpdatedAt: number
	monthlyCompensation: number
	bonusValue: number
	bonusUnit: CompensationUnit
	unit: CompensationUnit
	value: number
	type: string
	yearlySum: number
	salary: number
	disclaimer: CompensationDisclaimer
	postGrantMatchingAmount?: number
	postGrantEmployerBaseAmount?: number
	sliderValue: number // TODO: still valid?
	year: number // TODO: still valid?!
}

export interface ICompensationTableData {
	compensationType: ConfirmedCompensationType
	employerBaseAmount: number
	matchingAmount: number
	month: number
	ownContribution: number
	year: number
	reportingDate: string
	fundId: string
}

export interface IPageData {
	type: string
	queryKey: QueryKey
	apiRequest: any
	headline: string | React.ReactElement
	subheadline?: string | React.ReactElement
	requestData?: any
}
export interface ApiData {
	body?: { [key: string]: any } | string
	queryStringParameters?: { [key: string]: any }
}

export const vo20PensionRulSetCode = 'VO2020' as const
export const vo19PensionRulSetCode = 'VO2019' as const
const pensionRuleSetCodes = [vo19PensionRulSetCode, vo20PensionRulSetCode] as const
export type PensionRuleSetCode = (typeof pensionRuleSetCodes)[number]

export enum QueryKey {
	leistungsfallEmBu = 'leistungsfallEmBu',
	unresolvedSyncConflict = 'unresolvedSyncConflict',
	austrittsbearbeitungen = 'austrittsbearbeitungen',
	austrittsschreiben = 'austrittsschreiben',
	austrittsbearbeitungNotizen = 'austrittsbearbeitungNotizen',
	austrittsbearbeitungenBearbeitende = 'austrittsbearbeitungenBearbeitende',
	austrittsbearbeitungFilterType = 'austrittsbearbeitungFilterType',
	austrittsbearbeitungStatus = 'austrittsbearbeitungStatus',
	austrittsbearbeitungenHistorie = 'austrittsbearbeitungenHistorie',
	austrittstask = 'austrittstask',
	bausteinKonto = 'bausteinKonto',
	bausteinkontoFinalisierung = 'bausteinkontoFinalisierung',
	relations = 'relations',
	allCompensations = 'allCompensations',
	compensationYear = 'compensationYear',
	compensationMonth = 'compensationMonth',
	employeeResult = 'employeeResult',
	adminGepflegteBankverbindung = 'adminGepflegteBankverbindung',
	pensioner = 'pensioner',
	pensionerBaseData = 'pensionerBaseData',
	pensionerAuszahlungsdatum = 'pensionerAuszahlungsdatum',
	pensionerSearchResults = 'pensionerResults',
	pensionerDocuments = 'pensionerDocuments',
	pensionerHistory = 'pensionerHistory',
	pensionerProfilePreview = 'pensionerProfilePreview',
	pensionerConfirmedCompensationsTable = 'pensionerConfirmedCompensationsTable',
	pensionerPensionAssets = 'pensionerPensionAssets',
	pensioneReset = 'pensionerReset',
	pensionerConfirmedAuszahlungOption = 'pensionerConfirmedAuszahlungOption',
	pensionerLeavingInformation = 'pensionerLeavingInformation',
	checkUser = 'checkUser',
	getLastAuth2Change = 'getLastAuth2Change',
	syncConflict = 'syncConflict',
	tickets = 'tickets',
	ticketModelIds = 'ticketModelIds',
	ticketModel = 'ticketModel',
	archivedTickets = 'archivedTickets',
	ticketHistory = 'ticketHistory',
	ticket = 'ticket',
	ticketTypes = 'ticketTypes',
	lockTicket = 'lockTicket',
	pensionAssetsViewIntervals = 'pensionAssetsViewIntervals',
	pensionAssetsReferenceYears = 'pensionAssetsReferenceYears',
	risikoleistungen = 'risikoleistungen',
	identnumbersexport = 'identnumbersexport',
	transactions = 'wandlungswuensche',
	pensionerNotes = 'pensionerNotes',
	documentTypes = 'document-types',
	versorgungsausgleich = 'versorgungsausgleich',
	leistungsdaten = 'leistungsdaten',
	pdfJobs = 'pdfJobs',
	callerHistory = 'callerHistory',
	leavingInformation = 'leavingInformation',
	masterDataFlag = 'masterDataFlag',
	austrittDokumenteGet = 'austrittDokumenteGet',
	austrittDokumentePost = 'austrittDokumentePost',
	austrittDokumenteSchreibenProperties = 'austrittDokumenteSchreibenProperties',
	austrittDokumenteAnfragePost = 'austrittDokumenteAnfragePost',
	confirmedAuszahlungGet = 'confirmedAuszahlungGet',
	payoutModel = 'payoutModel',
	confirmedAuszahlungPost = 'confirmedAuszahlungPost',
}

export interface Query<V> {
	apiCall: (...args: any[]) => void
	queryParameters?: { [key: string]: string | number }
	variables?: V
}

export interface TicketModelId {
	type: string
	version: string
	versionAnnotation?: string
}

export interface IWawuExportProps {
	month: string
	year: string
	run: 'dry-run' | 'prod-run'
}

function prepareError(err: any): {
	success: false
	error: { message: string; status?: number }
} {
	if (!err.isAxiosError) {
		return {
			success: false,
			error: { message: 'message' in err ? String(err.message) : 'unknown error' },
		} as const
	}
	const axiosError = err as AxiosError
	const status = axiosError.response?.status
	const errorMessage = String(axiosError.response?.data?.errorMessage ?? '')
	return {
		success: false,
		error: { message: errorMessage, status },
	} as const
}

export const apiDataObject = async (dataObject?: ApiData) => {
	const data = {
		...dataObject,
		headers: {
			Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
		},
	}
	return data
}

const useApi = () => {
	const getTickets: QueryFunction<TicketResponse | undefined, [QueryKey.tickets, TicketRequestQueryParams]> = async ({
		queryKey,
	}) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					...queryStringParameters,
					maxItems: 100,
				},
			})
		)

		const responseData = await trackPromise(
			API.get('adminApi', 'global-inbox', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getNextTicket = async (queryStringParameters: NextTicketRequestQueryParams): Promise<NextTicketResponse> => {
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters,
			})
		)

		const responseData = await trackPromise(
			API.get('adminApi', 'global-inbox/next', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getTicketTypes = async (): Promise<ITicketType[] | undefined> => {
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.get('adminApi', 'ticket-model', data)
				.then((response: ITicketType[]) => {
					return response
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getBankverbindung: QueryFunction<
		BankverbindungGETResponse | undefined,
		[QueryKey.adminGepflegteBankverbindung, {}]
	> = async ({ queryKey }: { queryKey: any }): Promise<BankverbindungGETResponse> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/admin-gepflegte-bankverbindung`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const validiereIban = async (body: IbanValidierungPOSTRequestBody): Promise<IbanValidierungPOSTResponse> => {
		const data = await trackPromise(apiDataObject({ body }))
		const responseData = await trackPromise(
			API.post('adminApi', `validate-iban`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const postBankverbindung = async ({
		userId,
		body,
	}: {
		userId: string
		body: BankverbindungRequestBody
	}): Promise<BankverbindungPOSTResponse> => {
		const data = await trackPromise(apiDataObject({ body }))
		const responseData = await trackPromise(
			API.post('adminApi', `pensioners/${userId}/admin-gepflegte-bankverbindung`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const deleteBankverbindung = async ({ userId }: { userId: string }): Promise<BankverbindungDELETEResponse> => {
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.del('adminApi', `pensioners/${userId}/admin-gepflegte-bankverbindung`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getMasterDataAuszahlungsdatum: QueryFunction<
		AuszahlungsdatumGETResponse | undefined,
		[QueryKey.pensionerAuszahlungsdatum, {}]
	> = async ({ queryKey }: { queryKey: any }): Promise<AuszahlungsdatumGETResponse> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/auszahlungsdatum`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)
		return responseData
	}

	const postMasterDataAuszahlungsdatum = async ({
		userId,
		body,
	}: {
		userId: string
		body: { month: number; year: number }
	}): Promise<AuszahlungsdatumPOSTResponse> => {
		const data = await trackPromise(apiDataObject({ body }))
		const responseData = await trackPromise(
			API.post('adminApi', `pensioners/${userId}/auszahlungsdatum`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					if (err.response.status) {
						const toastProps: ZFToastProps = {
							title: 'Auszahlungsdatum',
							content: err.response.data.errorMessage,
							type: 'error',
						}
						zfToast(toastProps)
						return err.response.status
					}
				})
		)
		return responseData
	}
	const deleteMasterDataAuszahlungsdatum = async ({
		userId,
	}: {
		userId: string
	}): Promise<AuszahlungsdatumDELETEResponse> => {
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.del('adminApi', `pensioners/${userId}/auszahlungsdatum`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getMasterDataLeistungsfallEmBu: QueryFunction<
		LeistungsfallEmBuGETResponse | undefined,
		[QueryKey.leistungsfallEmBu, {}]
	> = async ({ queryKey }: { queryKey: any }): Promise<LeistungsfallEmBuGETResponse> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/leistungsfall-em-bu`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)
		return responseData
	}

	const postMasterDataLeistungsfallEmBu = async ({
		userId,
		body,
	}: {
		userId: string
		body: LeistungsfallEmBuRequestBody
	}): Promise<LeistungsfallEmBuPOSTResponse> => {
		const data = await trackPromise(apiDataObject({ body }))
		const responseData = await trackPromise(
			API.post('adminApi', `pensioners/${userId}/leistungsfall-em-bu`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log(err)
					if (err.message) {
						const toastProps: ZFToastProps = {
							title: 'Leistungsfall EM/BU',
							content: err.response.data.errorMessage,
							type: 'error',
						}
						zfToast(toastProps)
						return err.response.status
					}
				})
		)
		const toastProps: ZFToastProps = {
			title: 'Leistungsfall EM/BU',
			content: 'Änderung wurde abgeschickt.',
			type: 'success',
		}
		zfToast(toastProps)
		return responseData
	}

	const getMasterDataFlagsByIdentNumber: QueryFunction<
		MasterDataFlags | undefined,
		[QueryKey.masterDataFlag, {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/master-data-flag`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}
	const putMasterDataFlagByIdentNumber = async ({ body, userId }: { userId: string; body: PUTMasterDataFlag }) => {
		const data = await trackPromise(
			apiDataObject({
				body,
			})
		)
		const responseData = await trackPromise(
			API.put('adminApi', `pensioners/${userId}/master-data-flag`, data)
				.then((response) => response)
				.catch((err) => {
					const toastProps: ZFToastProps = {
						title: 'Mitarbeiter Info',
						content: getToastMessage(err.response.status),
						type: 'error',
					}
					zfToast(toastProps)
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const deleteMasterDataFlagByIdentNumber: QueryFunction<
		MasterDataFlags,
		[QueryKey.masterDataFlag, { userId: string; flagKey: string }]
	> = async ({ queryKey }) => {
		const [, { userId, flagKey }] = queryKey
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					flagKey,
				},
			})
		)
		const responseData = await trackPromise(
			API.del('adminApi', `pensioners/${userId}/master-data-flag`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getAustrittsbearbeitungen: QueryFunction<
		AustrittsbearbeitungenResponse | undefined,
		[QueryKey.austrittsbearbeitungen, AustrittsbearbeitungenRequestQueryParams]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		for (let key in queryStringParameters) {
			if (queryStringParameters[key as keyof AustrittsbearbeitungenRequestQueryParams] === undefined) {
				delete queryStringParameters[key as keyof AustrittsbearbeitungenRequestQueryParams]
			}
		}
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters,
			})
		)
		const responseData = await trackPromise(
			API.get('adminApi', 'austrittsbearbeitungen', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getAustrittsbearbeitungenStammdatenByIdentNumber: QueryFunction<
		AustrittsbearbeitungenStammdatenResponse | undefined,
		[QueryKey.austrittsbearbeitungen, {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/stammdaten`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getAustrittsbearbeitungenHistorieByIdentNumber: QueryFunction<
		AustrittsbearbeitungenHistorie | undefined,
		[QueryKey.austrittsbearbeitungenHistorie, {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/historie`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getAustrittsbearbeitungenBearbeitende: QueryFunction<
		AustrittsbearbeitungenBearbeitendeResponse | undefined,
		[QueryKey.austrittsbearbeitungen, {}]
	> = async () => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/bearbeitende`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const postAustrittsbearbeitungenAbschlussOhneVersorgungsguthaben = async ({
		userId,
	}: {
		userId: string
	}): Promise<{ success: boolean }> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/abschluss-ohne-versorgungsguthaben`, data)
				.then(() => {
					return { success: true }
				})
				.catch(() => {
					return { success: false }
				}),
			'abschlussOhneGuthaben'
		)

		return responseData
	}

	const getArchivedTickets: QueryFunction<
		TicketResponse | undefined,
		[QueryKey.tickets, TicketRequestQueryParams]
	> = async ({ queryKey }) => {
		const [, queryStringParameters] = queryKey

		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					...queryStringParameters,
					maxItems: 100,
				},
			})
		)

		const responseData = await trackPromise(
			API.get('adminApi', 'global-inbox-archive', data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getTicket = async ({ queryKey }: { queryKey: any }): Promise<TicketResult | undefined> => {
		const [, { ticketId }] = queryKey

		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `global-inbox/${ticketId}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getTicketModelIds = async (): Promise<TicketModelId[] | undefined> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `ticket-model`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getTicketModel = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<MagicLetterDocumentMetaData<AnalyzedMagicField<string>> | undefined> => {
		const [, { type, version }] = queryKey

		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `ticket-model/${type}/version/${version}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	/**
	 * this function is called without listening to its result
	 * therefore no trackPromise is needed
	 *
	 */
	const lockTicket = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<
		| {
				expiresAt: ISODateString
		  }
		| undefined
	> => {
		const [, { ticketId }] = queryKey
		const data = await apiDataObject()

		const responseData = await API.post('adminApi', `global-inbox/${ticketId}/lock`, data)
			.then((response) => response)
			.catch((err) => {
				console.log(err)
				return undefined
			})

		return responseData
	}

	/**
	 * this function is called without listening to its result
	 * therefore no trackPromise is needed
	 *
	 */
	const unlockTicket = async (ticketId: Ticket['id']): Promise<void> => {
		const data = await apiDataObject()

		const responseData = await API.del('adminApi', `global-inbox/${ticketId}/lock`, data)
			.then((response) => response)
			.catch((err) => {
				console.log(err)
			})

		return responseData
	}

	const updateTicket = async ({ ticketId, body }: { ticketId: string; body: Ticket }): Promise<void> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', `global-inbox/${ticketId}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)

		return responseData
	}

	const getUnresolvedSyncConflicts = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<GETSyncConflictsResponse | undefined> => {
		const data = await trackPromise(apiDataObject())
		const responseData = await trackPromise(
			API.get('adminApi', `sync-conflicts`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)
		return responseData
	}

	const getSingleSyncConflict = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<GETSingleSyncConflictResponse | undefined> => {
		const data = await trackPromise(apiDataObject())
		const [, { identNumber, timeStamp, uuid }] = queryKey
		const responseData = await trackPromise(
			API.get('adminApi', `sync-conflicts/${identNumber}?timeStamp=${timeStamp}&uuid=${uuid}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)

		return responseData
	}

	const postSingleSyncConflict = async ({
		syncConflictInfo: { identNumber, timeStamp, uuid },
		body,
	}: {
		syncConflictInfo: POSTSingleSyncConflictInfo
		body: POSTSingleSyncConflictRequestBody
	}): Promise<GETSyncConflictsResponse | undefined> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.post('adminApi', `sync-conflicts/${identNumber}?timeStamp=${timeStamp}&uuid=${uuid}`, data)
				.then((response) => {
					if (response) {
						const toastProps: ZFToastProps = {
							title: 'Sync-Konflikt Lösen',
							content: t(`view.syncConflict.message.success`),
							type: 'success',
						}
						zfToast(toastProps)
						return response.status
					}
					return response
				})
				.catch((err) => {
					console.log(err)
					if (err.response.data.errorMessage) {
						const toastProps: ZFToastProps = {
							title: 'Sync-Konflikt Lösen',
							content: (err.response.data.errorMessage as string).substring(4),
							type: 'error',
						}
						zfToast(toastProps)
						return err.response.status
					}
				})
		)

		return responseData
	}

	const updateTicketInfo = async ({
		ticketId,
		body,
	}: {
		ticketId: string
		body: Partial<VersionedTicketContent>
	}): Promise<Partial<VersionedTicketContent>> => {
		const data = await trackPromise(apiDataObject({ body }))

		const responseData = await trackPromise(
			API.patch('adminApi', `global-inbox/${ticketId}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)

		return responseData
	}

	/**
	 * this function is called without listening to its result
	 * therefore no trackPromise is needed
	 *
	 */
	const deleteTicket = async (ticketId: Ticket['id']): Promise<void> => {
		const data = await apiDataObject()

		const responseData = await API.del('adminApi', `global-inbox/${ticketId}`, data)
			.then((response) => response)
			.catch((err) => {
				console.log(err)
			})

		return responseData
	}

	const getDocumentTypes = async (): Promise<IDocumentCluster[]> => {
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `document-types`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const closeTicketWithoutAction = async (
		ticketId: Ticket['id'],
		documentType: string,
		identNumber: string | undefined
	): Promise<void> => {
		const queryStringParameters = {
			'document-type': documentType,
			'ident-number': identNumber,
		}
		const data = await apiDataObject({ queryStringParameters })
		const responseData = await API.post('adminApi', `global-inbox/${ticketId}/close-without-action`, data)
			.then((response) => response)
			.catch((err) => {
				console.log(err)
			})

		return responseData
	}

	const resetUser = async ({
		userId,
		check,
	}: {
		userId: string
		check: string
	}): Promise<PensionAuthData | undefined> => {
		// const [, { userId, check }] = queryKey
		const queryStringParameters = { check }

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const apiPath = `pensioners/${userId}/pensionReset`

		const responseData = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					return undefined
				})
		)

		return responseData
	}
	const checkUser = async ({ queryKey }: { queryKey: any }): Promise<string | undefined> => {
		const [, { userId }] = queryKey
		const check = true
		const queryStringParameters = { check }

		const data = await trackPromise(apiDataObject({ queryStringParameters }))
		const apiPath = `pensioners/${userId}/pensionReset`

		const responseData = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getLastAuthChange = async ({ queryKey }: { queryKey: any }): Promise<string | undefined> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject({}))
		const apiPath = `pensioners/${userId}/pensionReset2`

		const responseData = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const postResetUserAuth2 = async ({
		userId,
	}: {
		userId: string
	}): Promise<PasswortResetAuth2QueryParams | undefined> => {
		const data = await trackPromise(apiDataObject())

		const apiPath = `pensioners/${userId}/pensionReset2`

		const responseData = await trackPromise(
			API.post('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getIdentNumberExport = async ({ queryKey }: { queryKey: any }): Promise<string | undefined> => {
		const { dryRun } = queryKey
		const queryStringParameters = { dryRun }

		const data = await trackPromise(apiDataObject({ queryStringParameters }))
		const apiPath = `maintenance/identnumbers-export`

		const responseData = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const pdfJobs = async (): Promise<{ [key: string]: PdfJob } | undefined> => {
		const data = await trackPromise(apiDataObject({}))

		const apiPath = `maintenance/active-jobs`

		const responseData = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const pdfMerge = async ({
		entityId,
		documentType,
		fileExtension,
	}: PdfMergeQueryParams): Promise<PdfMergeQueryResponse> => {
		const queryStringParameters = { entityId, documentType, fileExtension }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.get('adminApi', `maintenance/merge-job`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)
		return response
	}

	const pdfCopy = async ({ entityId, documentType }: PdfMergeQueryParams): Promise<any | undefined> => {
		const queryStringParameters = { entityId, documentType }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.get('adminApi', `maintenance/transfer-to-s3`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const pdfSendToSPO = async ({ entityId, documentType }: PdfMergeQueryParams): Promise<any | undefined> => {
		const queryStringParameters = { entityId, documentType }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.get('adminApi', `maintenance/transfer-to-spo`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const pdfRemoveMiniJobs = async ({ entityId }: { entityId: string }): Promise<any | undefined> => {
		const queryStringParameters = { entityId }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.get('adminApi', `maintenance/remove-jobs`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const pdfUploadCsv = async (data: string, fileName: string): Promise<any | undefined> => {
		const body = { data, fileName }
		const payload = await trackPromise(apiDataObject({ body }))
		console.log('uploading csv..')
		const response = await trackPromise(
			API.post('adminApi', `maintenance/upload-csv`, payload)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const getPensionerSearchResults = async (
		key: QueryKey.pensionerSearchResults, // key is needed for useQuery hook
		queryStringParameters: PensionerSearchQueryParams
	): Promise<PensionerSearchResult[] | void> => {
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const responseData = await trackPromise(
			API.get('adminApi', 'search', data)
				.then((response: { data: PensionerSearchResult[] }) => {
					return response.data
				})
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getPensionerProfilePreview = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<IPensionerProfilePreview | undefined> => {
		const [, { userId }] = queryKey

		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/pensionPreview`, data)
				.then((response) => response.data)
				.catch((err) => {
					console.log(err)
					return
				})
		)

		return responseData
	}

	const getPensionerBaseData = async ({ queryKey }: { queryKey: any }): Promise<IPensionerBaseData | undefined> => {
		const [, { userId }] = queryKey

		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/profile/pensionPlan`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getDocuments = async ({ queryKey }: { queryKey: any }): Promise<DocumentData[] | undefined> => {
		const [, { userId }] = queryKey

		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/documents`, data)
				.then((response: { documents: DocumentData[] }) => response.documents)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return responseData
	}

	const getDocumentLink = async ({
		userId,
		id,
		area,
	}: {
		userId: string
		id: DocumentData['id']
		area: string
	}): Promise<string | void> => {
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/documents/${id}`, data)
				.then((response: { url: string }) => response.url)
				.catch((err) => {
					console.log(err)
					return
				}),
			area
		)

		return responseData
	}

	const resendDocument = async (
		identNumber: string,
		documentId: ResendDocumentPostBody
	): Promise<ResendDocumentResponse> => {
		const data = await trackPromise(apiDataObject({ body: documentId }))
		const response = await trackPromise(
			API.post('adminApi', `pensioners/${identNumber}/documents/erneut-versenden`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
				})
		)
		return response
	}

	const getCompensation = async ({ queryKey }: { queryKey: any }): Promise<CompensationChartData[] | undefined> => {
		const [, { userId, year }] = queryKey
		const queryStringParameters = { year }
		const endpoint = year ? 'year' : 'all'

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const apiPath = `pensioners/${userId}/pensionScheme/${endpoint}`

		const response = await trackPromise(
			API.get('adminApi', apiPath, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		if (response && year) {
			// FIXME: total amount should come from backend
			return response.map((dataEntry: CompensationChartData) => {
				;(dataEntry.y1 as number) += dataEntry.y2 as number
				delete dataEntry.y2

				return dataEntry
			})
		}

		return response
	}

	const getSingleCompensation = async ({ queryKey }: { queryKey: any }): Promise<MonatlicheBeitraege | undefined> => {
		const [, { userId, year, month }] = queryKey
		const queryStringParameters = { year, month }

		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/pensionScheme/month`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const getConfirmedCompensationsTable = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<ICompensationTableData[] | undefined> => {
		const [, { userId, area }] = queryKey

		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/confirmedCompensations`, data)
				.then((response: { compensations: ICompensationTableData[] }) => response.compensations)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			area
		)

		return responseData
	}

	const getRisikoLeistungen = async ({ queryKey }: { queryKey: any }): Promise<IRiskOptions | undefined> => {
		const [, { userId, area }] = queryKey
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/risikoleistungen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getAllTransactions = async ({ queryKey }: { queryKey: any }): Promise<ITransaction[] | undefined> => {
		const [, { userId, area }] = queryKey
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/wandlungswuensche`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getConfirmedAuszahlungOption = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<AuszahlungsOptionConfirmed | undefined> => {
		const [, { userId, area }] = queryKey
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/confirmedAuszahlung`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getVersorgungsausgleich = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<IVersorgungsausgleich | undefined> => {
		const [, { userId, area }] = queryKey
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/versorgungsausgleich`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getLeistungsdaten = async ({ queryKey }: { queryKey: any }): Promise<any | undefined> => {
		const [, { userId, area }] = queryKey
		const data = await trackPromise(apiDataObject(), area)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/leistungsdaten`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const getPensionAssets = async ({ queryKey }: { queryKey: any }): Promise<PensionAssets | undefined> => {
		const [, { userId, queryStringParameters, area }] = queryKey

		const updatedQueryParameters = Object.entries(queryStringParameters).reduce(
			(currentParams: { [key: string]: string }, [key, value]: any) => {
				if ('year' === key && 'all' === value) {
					return currentParams
				}

				currentParams[key] = value
				return currentParams
			},
			{}
		)

		const data = await trackPromise(apiDataObject({ queryStringParameters: updatedQueryParameters }), area)

		const pensionAssetsData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/pensionAssets`, data)
				.then((response: PensionAssets) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			area
		)

		return pensionAssetsData
	}

	const getTicketHistory: QueryFunction<
		TicketResponse | undefined,
		[QueryKey.ticketHistory, TicketRequestQueryParams, String]
	> = async ({ queryKey }) => {
		const [, queryStringParameters, userId] = queryKey
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					...queryStringParameters,
					maxItems: 100,
				},
			})
		)

		const responseData = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/ticketHistory`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return responseData
	}

	const executeAllEmployeeExport = async (): Promise<void> => {
		const data = await trackPromise(apiDataObject())

		API.get('adminApi', `maintenance/employee-export-execution`, data).catch((err) => {
			console.log("Error within 'executeAllEmployeeExport':", err)
		})
	}

	const getAllEmployeeExport = async (): Promise<any | undefined> => {
		const data = await trackPromise(apiDataObject())

		const response = await trackPromise(
			API.get('adminApi', `maintenance/employee-export`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const getWawuExport = async (props: IWawuExportProps): Promise<any | undefined> => {
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					...props,
				},
			})
		)
		const response = await trackPromise(
			API.get('adminApi', `maintenance/wawu-export`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const getAllTransactionsExport = async (): Promise<any | undefined> => {
		const data = await trackPromise(apiDataObject())

		const response = await trackPromise(
			API.get('adminApi', `maintenance/all-transactions-export`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const getAllErstanschreibenCandidatesExport = async (): Promise<any | undefined> => {
		const data = await trackPromise(apiDataObject())

		const response = await trackPromise(
			API.get('adminApi', `maintenance/all-erstanschreiben-candidates-export`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const getPlanvermoegenReport = async (monat: number, jahr: number): Promise<any> => {
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					monat,
					jahr,
				},
			})
		)
		return await trackPromise(
			API.get('adminApi', `maintenance/planvermoegen-report-query`, data).then((response) => response)
		)
	}

	const getSummenbildungReport = async (monat: number, jahr: number): Promise<any> => {
		const data = await trackPromise(
			apiDataObject({
				queryStringParameters: {
					monat, 
					jahr
				}
			})
		)
		return await trackPromise(
			API.get('adminApi',`maintenance/summenbildung-report`, data ).then((response) => response)
		)
	}

	const getRiskTransactionExport = async (): Promise<string | undefined> => {
		const data = await trackPromise(apiDataObject())
		const response = await trackPromise(
			API.get('adminApi', `maintenance/all-risk-transactions-export`, data)
				.then((response) => response)
				.catch((err) => {
					console.error(err)
					return undefined
				})
		)

		return response
	}

	const getBausteinKonto = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<components['schemas']['BausteinkontoOverview'] | void> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const bausteine = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/bausteinkonto`, data)
				.then((response: components['schemas']['BausteinkontoOverview']) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)
		return bausteine
	}

	const getAustrittsbearbeitungFilterTypes = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<string[] | undefined> => {
		const [, { filterType }] = queryKey
		const data = await trackPromise(apiDataObject())
		const types = await trackPromise(
			API.get('adminApi', `austrittsbearbeitung-filter-types/${filterType}`, data)
				.then((response: string[]) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)
		return types ? types : undefined
	}

	const getRelations = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<components['schemas']['Relations'] | undefined> => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const result = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/relations`, data)
				.then((response: components['schemas']['Relations']) => response)
				.catch((err) => {
					console.log(err)
					return
				})
		)
		return result ?? undefined
	}

	const getPensionerNotes = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<PensionerNotesQueryResult | undefined> => {
		const [, { pensionerId }] = queryKey
		const data = await trackPromise(apiDataObject())
		const response = await trackPromise(
			API.get('adminApi', `pensioners/${pensionerId}/notes`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const postNote = async (pensionerId: string, content: string): Promise<ITimelineNoteContent | undefined> => {
		const queryStringParameters = { content }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.post('adminApi', `pensioners/${pensionerId}/notes`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const updateNote = async (
		pensionerId: string,
		noteId: string,
		createdAt: string,
		content: string
	): Promise<ITimelineNoteContent | undefined> => {
		const queryStringParameters = { noteId, createdAt, content }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.patch('adminApi', `pensioners/${pensionerId}/notes`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const deleteNote = async (
		pensionerId: string,
		noteId: string,
		createdAt: string
	): Promise<ITimelineNoteContent | undefined> => {
		const queryStringParameters = { noteId, createdAt }
		const data = await trackPromise(apiDataObject({ queryStringParameters }))

		const response = await trackPromise(
			API.del('adminApi', `pensioners/${pensionerId}/notes`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const startAustrittstask = async (
		identNumber?: string
	): Promise<AustrittsbearbeitungenPostSuccessResponse | AustrittsbearbeitungenPostErrorResponse> => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${identNumber}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					const response = {
						success: false,
						statusCode: err.response.status,
					}
					return response
				})
		)
		return response
	}

	const editAustrittstask = async (identNumber: string, body: AustritttaskRequestBody) => {
		const data = await trackPromise(apiDataObject({ body }))
		const response = await trackPromise(
			API.patch('adminApi', `austrittsbearbeitungen/${identNumber}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const deleteAustrittstask = async (
		identNumber?: string
	): Promise<AustrittsbearbeitungenPostSuccessResponse | AustrittsbearbeitungenPostErrorResponse> => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.del('adminApi', `austrittsbearbeitungen/${identNumber}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					const response = {
						success: false,
						statusCode: err.response.status,
					}
					return response
				})
		)
		return response
	}

	const getAustrittstaskByIdentNumber: QueryFunction<
		{
			statusCode: number
			response?: AustrittstaskResponse
		},
		[QueryKey.austrittstask, {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const [, { userId }] = queryKey
		const data = await trackPromise(apiDataObject())

		const responseData = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}`, data)
				.then((response) => {
					return {
						response,
						statusCode: 200,
					}
				})
				.catch((err) => {
					return {
						statusCode: err.response.status,
					}
				})
		)
		return responseData
	}

	const getAustrittVersorgungsguthaben: QueryFunction<
		AustrittVersorgungsguthabenResponse | undefined,
		['assets', {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const data = await apiDataObject()
		const [, { userId }] = queryKey
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/versorgungsguthaben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const getAustrittsAuszahlungsmodell: QueryFunction<
		AustrittsAuszahlungsOptionenResponse | undefined,
		['payoutModel', {}]
	> = async ({ queryKey }: { queryKey: any }) => {
		const data = await apiDataObject()
		const [, { userId }] = queryKey
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/auszahlungsoptionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const updateAustrittsAuszahlungsmodell = async ({
		body,
		userId,
	}: {
		body: AustrittsbearbeitungsSimulationRequest
		userId: string
	}): Promise<AustrittsAuszahlungsSimulationResponse> => {
		const data = await apiDataObject({ body })

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/auszahlungsoptionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'updateAustrittsAuszahlungsmodell'
		)

		return response
	}

	const createUVSchreiben = async (userId: string) => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/uv-schreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return err
				}),
			'UVSchreiben'
		)

		return response
	}

	const getUVschreibenOptionen: QueryFunction<GetUVSchreibenOptionenReponse | undefined> = async ({
		queryKey,
	}: {
		queryKey: any
	}) => {
		const [, { userId }] = queryKey
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/uv-schreiben-optionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'getUVschreibenOptionen'
		)
		return response
	}

	const postUVschreibenOptionen = async (body: PostUVschreibenOptionenRequestBody, userId: string) => {
		const data = await apiDataObject({ body })

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/uv-schreiben-optionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'postUVschreibenOptionen'
		)
		return response
	}

	const createZusageSchreiben = async (userId: string) => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/zusageschreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(`createZusageSchreiben Error: ${err.response.data.errorMessage}`)
					return err
				}),
			'ZusageSchreiben'
		)

		return response
	}

	const createAbfindungsSchreiben = async (userId: string) => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/abfindung-schreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return err
				}),
			'AbfindungsSchreiben'
		)

		return response
	}

	const getAustrittsschreiben: QueryFunction<
		AustrittsschreibenGetResponse,
		[QueryKey.austrittsschreiben, { userId: string | undefined }]
	> = async ({ queryKey }) => {
		const [, { userId }] = queryKey
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/austritt-schreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const getZusageSchreibenOptionen = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/zusageschreiben-optionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'getZusageschreibenOptionen'
		)
		return response
	}

	const getDownloadURLAustrittschreiben = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/austritt-schreiben-download-url`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'AustrittschreibenDownloadURL'
		)

		return response
	}

	const getDownloadURLZusageSchreiben = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/zusageschreiben-download-url`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'LeistungsFallDownloadURL'
		)

		return response
	}

	const closeUVFall = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/abschluss-uv-fall`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'abschlussUVFall'
		)

		return response
	}

	const closeLeistungsFall = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/abschluss-leistungsfall`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'abschlussLeistungsFall'
		)

		return response
	}

	const getAustrittsbearbeitungNotizen: QueryFunction<any, ['austrittsbearbeitungNotizen', {}]> = async ({
		queryKey,
	}: {
		queryKey: any
	}) => {
		const data = await apiDataObject()
		const [, { userId }] = queryKey

		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/notizen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const putAdressdaten = async (userId: string, body: PUTAdressdatenRequestBody) => {
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.put('adminApi', `pensioners/${userId}/adressdaten`, data)
				.then((response) => {
					const toastProps: ZFToastProps = {
						title: 'Adressdaten',
						content: 'Adressdaten wurde erfolgreich aktualisiert.',
						type: 'success',
					}
					zfToast(toastProps)
					return response
				})
				.catch((err) => {
					if (err.response.status) {
						const toastProps: ZFToastProps = {
							title: 'Adressdaten',
							content: getToastMessage(err.response.status),
							type: 'error',
						}
						zfToast(toastProps)
						return err.response.status
					}

					return undefined
				}),
			'adressdaten'
		)

		return response
	}

	const postAustrittsbearbeitungNotizen = async (userId: string, body: Record<string, string>) => {
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/notizen`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log(err, Object.keys(err.response), err.status, err.response)
					if (err.response.status) {
						return err.response.status
					}

					return undefined
				})
		)

		return response
	}

	const deleteAustrittsbearbeitungNotizen = async (userId: string, body: Record<string, string>) => {
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.del('adminApi', `austrittsbearbeitungen/${userId}/notizen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const getBausteinKontoFinalisierung: QueryFunction<BausteinkontoFinalisierungResponse | undefined> = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<BausteinkontoFinalisierungResponse | undefined> => {
		const [, { userId }] = queryKey

		const data = await apiDataObject()

		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/baustein-finalisierung`, data)
				.then((response) => {
					return response
				})
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'bausteinkontoFinalisierung'
		)

		return response
	}

	const postBausteinKontoFinalisierung = async (
		userId: string,
		doToast?: boolean
	): Promise<BausteinkontoFinalisierungResponse | undefined> => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/baustein-finalisierung`, data)
				.then((response) => response)
				.catch((err) => {
					if (doToast) {
						const toastProps: ZFToastProps = {
							title: 'Bausteinkonto Finalisierung',
							content: err.response.data.errorMessage,
							type: 'error',
						}
						zfToast(toastProps)
					}
					console.log(err.response)
					return undefined
				}),
			'bausteinkontoFinalisierung'
		)

		return response
	}

	const postZusageschreibenOptionen = async (body: PostBodyZusageschreibenOptionen, userId: string) => {
		const data = await apiDataObject({ body })

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/zusageschreiben-optionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'postZusageschreibenOptionen'
		)
		return response
	}

	const getZusageschreibenOptionen: QueryFunction<getZusageschreibenOptionen | undefined> = async ({
		queryKey,
	}: {
		queryKey: any
	}) => {
		const [, { userId }] = queryKey

		const data = await apiDataObject()

		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/zusageschreiben-optionen`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)

		return response
	}

	const putAnspruchVertraglichUnverfallbar = async ({
		userId,
		anspruchVertraglichUnverfallbar,
	}: { userId: string } & PutAnspruchVertraglichUnverfallbarParams) => {
		const data = await apiDataObject({
			body: {
				anspruchVertraglichUnverfallbar,
			} satisfies PutAnspruchVertraglichUnverfallbarParams,
		})

		const response = await trackPromise(
			API.put('adminApi', `pensioners/${userId}/anspruch-vertraglich-unverfallbar`, data)
				.then(() => ({ success: true } as const))
				.catch(prepareError)
		)
		return response
	}

	const getAustrittDokumente: QueryFunction<GETAustrittDokumente, ['austrittDokumenteGet', {}]> = async ({
		queryKey,
	}: {
		queryKey: any
	}): Promise<GETAustrittDokumente> => {
		const data = await apiDataObject()
		const [, { userId, fallart }] = queryKey
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokumente/${fallart}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				})
		)
		return response
	}

	const postAustrittDokumente = async ({
		body,
		userId,
		fallart,
		area,
	}: {
		body: POSTAustrittDokumente
		userId: string | undefined
		fallart: string
		area: string
	}): Promise<POSTAustrittDokumente> => {
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokumente/${fallart}`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'postAustrittDokumente'
		)
		return response
	}

	const postAustrittDokumenteAnfrage = async ({
		body,
		userId,
	}: {
		body: POSTAustrittDokumentAnfrage
		userId: string | undefined
	}): Promise<POSTAustrittDokumentAnfrage> => {
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokument-anfrage`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'postAustrittDokumenteAnfrage'
		)
		return response
	}

	const createAustrittDokumenteSchreiben = async (userId: string) => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokument-anfrage-schreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'AustrittDokumenteSchreiben'
		)

		return response
	}

	const getAustrittDokumenteSchreibenProperties = async (
		userId: string | undefined
	): Promise<GETAustrittDokumenteAnfrageSchreiben> => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokument-anfrage-schreiben`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'AustrittDokumenteSchreibenProperties'
		)

		return response
	}

	const getAustrittDokumenteSchreibenDownloadURL = async (userId: string) => {
		const data = await apiDataObject()
		const response = await trackPromise(
			API.get(
				'adminApi',
				`austrittsbearbeitungen/${userId}/austritt-dokument-anfrage-schreiben-download-url`,
				data
			)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'AustrittDokumenteSchreibenDownloadURL'
		)

		return response
	}

	const sendAustrittDokumenteSchreiben = async (userId: string) => {
		const data = await apiDataObject()

		const response = await trackPromise(
			API.post('adminApi', `austrittsbearbeitungen/${userId}/austritt-dokument-anfrage-schreiben-versand`, data)
				.then((response) => response)
				.catch((err) => {
					console.log(err)
					return undefined
				}),
			'AustrittDokumenteSchreibenSend'
		)

		return response
	}

	const getConfirmedAuszahlung: QueryFunction<
		GETConfirmedAuszahlung | undefined,
		[QueryKey.confirmedAuszahlungGet, { userId: string | undefined }]
	> = async ({ queryKey }: { queryKey: any }): Promise<GETConfirmedAuszahlung | undefined> => {
		const data = await apiDataObject()
		const [, { userId }] = queryKey

		const response = await trackPromise(
			API.get('adminApi', `pensioners/${userId}/confirmedAuszahlung`, data)
				.then((r) => r)
				.catch((e) => {
					console.log(e)
					return undefined
				}),
			'confirmedAuszahlungGetRequest'
		)
		return response
	}

	const postConfirmedAuszahlung = async ({
		userId,
		body,
	}: {
		userId: string | undefined
		body: POSTConfirmedAuszahlung
	}): Promise<POSTConfirmedAuszahlung> => {
		const data = await apiDataObject({ body })

		const response = await trackPromise(
			API.post('adminApi', `pensioners/${userId}/confirmedAuszahlung`, data)
				.then((r) => r)
				.catch((e) => {
					console.log(e)
					return undefined
				}),
			'confirmedAuszahlungPostRequest'
		)
		return response
	}

	const putAbfindungNichtAnwendbar = async (userId: string, abfindungNichtAnwendbar?: boolean) => {
		const body: PUTAbfindungNichtAnwendbarRequestBody = {
			abfindungNichtAnwendbar: abfindungNichtAnwendbar ?? true,
		}
		const data = await apiDataObject({ body })
		const response = await trackPromise(
			API.put('adminApi', `pensioners/${userId}/abfindung-nicht-anwendbar`, data)
				.then(() => {
					const toastProps: ZFToastProps = {
						title: 'Abfindung Nicht Anwendbar',
						content: 'Der Fall wurde erfolgreich als nicht abfindbar markiert.',
						type: 'success',
					}
					zfToast(toastProps)
				})
				.catch((err) => {
					console.log(err)
					const toastProps: ZFToastProps = {
						title: 'Abfindung Nicht Anwendbar',
						content: getToastMessage(err.response.status),
						type: 'error',
					}
					zfToast(toastProps)
					return undefined
				}),
			'abfindungNichtAnwendbar'
		)
		return response
	}

	return {
		getDocuments,
		getDocumentLink,
		getCompensation,
		getSingleCompensation,
		getConfirmedCompensationsTable,
		getBausteinKonto,
		getRelations,
		getAustrittsbearbeitungNotizen,
		postAustrittsbearbeitungNotizen,
		deleteAustrittsbearbeitungNotizen,
		getAustrittVersorgungsguthaben,
		getAustrittsAuszahlungsmodell,
		getMasterDataFlagsByIdentNumber,
		putMasterDataFlagByIdentNumber,
		deleteMasterDataFlagByIdentNumber,
		getAustrittsbearbeitungen,
		getAustrittsbearbeitungenHistorieByIdentNumber,
		getAustrittsbearbeitungenStammdatenByIdentNumber,
		getAustrittsbearbeitungenBearbeitende,
		postBausteinKontoFinalisierung,
		getTickets,
		getArchivedTickets,
		getTicket,
		getTicketModelIds,
		getTicketModel,
		getTicketTypes,
		lockTicket,
		unlockTicket,
		updateTicket,
		updateTicketInfo,
		deleteTicket,
		getDocumentTypes,
		closeTicketWithoutAction,
		getNextTicket,
		checkUser,
		postSingleSyncConflict,
		getSingleSyncConflict,
		getUnresolvedSyncConflicts,
		resetUser,
		getLastAuthChange,
		postResetUserAuth2,
		getPensionerSearchResults,
		getAustrittsbearbeitungFilterTypes,
		getPensionerBaseData,
		getPensionerProfilePreview,
		getPensionAssets,
		getTicketHistory,
		getAllEmployeeExport,
		getAllTransactionsExport,
		getAllErstanschreibenCandidatesExport,
		getConfirmedAuszahlungOption,
		getVersorgungsausgleich,
		getLeistungsdaten,
		getRiskTransactionExport,
		executeAllEmployeeExport,
		getWawuExport,
		getPlanvermoegenReport,
		getSummenbildungReport,
		pdfJobs,
		pdfMerge,
		pdfCopy,
		pdfSendToSPO,
		pdfRemoveMiniJobs,
		getRisikoLeistungen,
		pdfUploadCsv,
		getIdentNumberExport,
		getAllTransactions,
		getPensionerNotes,
		postNote,
		updateNote,
		deleteNote,
		startAustrittstask,
		editAustrittstask,
		getAustrittstaskByIdentNumber,
		postAustrittsbearbeitungenAbschlussOhneVersorgungsguthaben,
		updateAustrittsAuszahlungsmodell,
		createUVSchreiben,
		getDownloadURLAustrittschreiben,
		closeUVFall,
		getBausteinKontoFinalisierung,
		createAbfindungsSchreiben,
		getZusageSchreibenOptionen,
		getZusageschreibenOptionen,
		postZusageschreibenOptionen,
		getMasterDataLeistungsfallEmBu,
		postMasterDataLeistungsfallEmBu,
		createZusageSchreiben,
		closeLeistungsFall,
		getDownloadURLZusageSchreiben,
		putAnspruchVertraglichUnverfallbar,
		getAustrittDokumente,
		postAustrittDokumente,
		postAustrittDokumenteAnfrage,
		getAustrittDokumenteSchreibenProperties,
		createAustrittDokumenteSchreiben,
		getAustrittDokumenteSchreibenDownloadURL,
		sendAustrittDokumenteSchreiben,
		getMasterDataAuszahlungsdatum,
		postMasterDataAuszahlungsdatum,
		getAustrittsschreiben,
		deleteMasterDataAuszahlungsdatum,
		deleteAustrittstask,
		getBankverbindung,
		postBankverbindung,
		deleteBankverbindung,
		validiereIban,
		resendDocument,
		putAbfindungNichtAnwendbar,
		putAdressdaten,
		getConfirmedAuszahlung,
		postConfirmedAuszahlung,
		postUVschreibenOptionen,
		getUVschreibenOptionen,
	}
}

export default useApi
