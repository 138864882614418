/*
* Copied and adapted from PensionerTransactionTable.tsx*
*/

import GridList from 'components/GridList'
import GridListCell from 'components/GridListCell'
import GridListRow from 'components/GridListRow'
import { ILeistungsdaten } from 'hooks/useApi'
import React, { FunctionComponent } from 'react'
import { useTranslation } from 'react-i18next'
import {currencyFormat} from "../shared/helper/numberFormats";


export interface PensionerLeistungsdatenProps {
	className?: string
	data: ILeistungsdaten[] | undefined;
}

const PensionerLeistungsdatenTable: FunctionComponent<PensionerLeistungsdatenProps> = props => {
	const { t } = useTranslation()
	const { data } = props
	const tableHeaders = [
		'renteneintritt',
		'leistungsbeginn',
		'lohnartCode',
		'betrag',
		'meldedatum',
	]

	const renderTableHeader = () => {
		const headerCellClass = 'grid-list__cell--header'

		return (
			<GridListRow className={'grid-list__row--header'}>
				{tableHeaders.map((label, index) => (
					<GridListCell className={headerCellClass} key={`header-field-${index}`}>
						{t(`view.pensionerLeistungsdaten.tableHeaders.${label}`)}
					</GridListCell>
				))}
			</GridListRow>
		)
	}

	const renderTableRows = () => {
		if (!data) {
			return
		}

		data.sort((leistungsdaten01, leistungsdaten02) => {
			const leistungsbeginn01 = leistungsdaten01.leistungsbeginn.split('.').reverse().join('')
			const leistungsbeginn02 = leistungsdaten02.leistungsbeginn.split('.').reverse().join('')
			return leistungsbeginn01.localeCompare(leistungsbeginn02);
		})

		return (
			<>
				{
					data.map((entry, index: number) => (
					<GridListRow key={`entry-${index}`}>
						{tableHeaders.map((key, fieldIndex: number) => {
							let transformedValue: string

							let classes = ['user-select--all']

							switch (key) {
								case 'renteneintritt':
								case 'leistungsbeginn':
								case 'meldedatum':
									transformedValue = entry[key] ?? '-'
									break
								case 'lohnartCode':
									transformedValue = entry[key] + ' (' + entry['lohnartLangtext'] + ')'
									break
								case 'betrag':
									transformedValue = currencyFormat(entry[key])
									classes.push('grid-list__cell--number')
									break
								default:
									transformedValue = '-'
							}

							const cellClasses = classes.join(" ")

							return (
								<GridListCell key={`entry-field-${index}-${fieldIndex}`} className={cellClasses}>
									{transformedValue}
								</GridListCell>
							)
						})}
					</GridListRow>
				))}
			</>
		)
	}


	return (
		<>
			{data?.length !== 0 && (
				<>
					<GridList className="delegate__results" columnCount={tableHeaders.length}>
						{renderTableHeader()}
						{renderTableRows()}
					</GridList>
				</>
			)}
		</>
	)
}

export default PensionerLeistungsdatenTable
